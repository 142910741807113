@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	scroll-behavior: smooth;
}

@font-face {
	font-family: 'campton_b';
	src: local('campton-bold'), url('./assets/fonts/CamptonBold.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'campton_eb';
	src: local('campton-extrabold'), url('./assets/fonts/CamptonExtraBold.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'campton_sb';
	src: local('campton-semibold'), url('./assets/fonts/CamptonSemiBold.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'campton_m';
	src: local('campton-medium'), url('./assets/fonts/CamptonMedium.ttf') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'campton_r';
	src: local('campton-light'), url('./assets/fonts/CamptonLight.ttf') format('woff2');
	font-display: swap;
}

.react-datepicker-popper {
	z-index: 80 !important;
}

.card-description {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.stat-card {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

/* Modal Styles */
.overlay {
	width: 100%;
	height: 100%;
	background: #0F2E38;
	opacity: 24%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
}

.modal {
	position: absolute;
	z-index: 60;
}

.modal-center {
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	left: 50%;
	opacity: 1;
	transition: 1s;
}

.modal-right {
	top: 0;
	right: 0;
	opacity: 1;
	transition: 1s;
	display: flex;
}

.modal-right-body {
	height: 89dvh;
	padding-bottom: 60px;
	overflow-y: auto;
}

.modal-right-button-container {
	display: flex;
	justify-content: flex-end;
	padding: 16px 0 0;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 100%;
	background-color: #ffffff;
}

@media only screen and (max-width: 768px) {
	.modal-right {
		display: block;
		margin-top: 100px;
	}

	.modal-right-body {
		height: calc(84dvh - 100px);
		overflow-y: auto;
	}

	.modal-right-button-container {
		padding: 16px;
		position: fixed;
		bottom: 0;
		right: 0;
	}
}

.side-drawer {
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 200;
	transform: translateX(100%);
	transition: transform 0.3s ease-out;
}

.side-drawer.open {
	transform: translateX(0);
}

@keyframes modal_animation {
	0% {
		opacity: 0;
		transform: translateX(-50%) translateY(-50%) scale(.8);
	}

	100% {
		opacity: 1;
		transform: translateX(-50%) translateY(-50%) scale(1);
	}
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

.auth_bg {
	background: linear-gradient(0deg, rgba(20, 53, 102, 0.96), rgba(20, 53, 102, 0.96)), url('./assets/svg/noise.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.react-datepicker-wrapper {
	border-color: '#DFE2E2' !important;
	width: 100%;
}

.ellipsis_2_lines {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Make clicks pass-through */
#nprogress {
	pointer-events: none;
	background-color: #F7F9FB;
}

#nprogress .bar {
	background: #143566;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 4px;
}

.spinner-icon {
	width: 18px;
	height: 18px;
	box-sizing: border-box;

	border: solid 2px #36A1C5;
	border-top-color: #ffffff;
	border-left-color: #ffffff;
	border-radius: 50%;

	-webkit-animation: spinner 400ms linear infinite;
	animation: spinner 400ms linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.scrollable-element {
	scrollbar-color: #36A1C5;
	height: 4px;
	width: 40px;
}

.tabs__item {
	position: relative;
	display: inline-block;
	font-size: 14px;
}

.tabs__item:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3px;
	width: 0;
	background: #36A1C5;
	transition: 0.3s;
}

.tabs__item.active:after {
	width: 100%;
}

.tabs__item:hover {
	cursor: pointer;
}

/* The container */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

*:focus:not(.focus-visible) {
	outline: none;
}

/* Hide the browser's default radio button */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #FFFFFF;
	border: 1px Solid #DFE2E2;
	border-radius: 50%;
}

/* When the radio button is checked, add a background */
.container input:checked~.checked-active {
	background-color: #FF7A21;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
	top: 5px;
	left: 5px;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	border: none;
	background: white;
}

.hover {
	filter: blur(0px);
	opacity: 1;
	transform: scale(.90);
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 48px;
	height: 22px;
	border-radius: 50px;
}

.switch_active {
	background-color: #23CE6B;
}

.switch_inactive {
	background-color: #ccc;
}

.switchball {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	bottom: 2px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.active_switchball {
	right: 3px;
}

.inactive_switchball {
	left: 3px;
}

.slider.round {
	border-radius: 50px;
}

.slider.round:before {
	border-radius: 60%;
}

.transition_left {
	opacity: 1;
	transform: translateX(0px);
	transition: 1s;

	-webkit-animation: left_animation 300ms linear;
	animation: left_animation 300ms linear;
}

.IIV::-webkit-media-controls-play-button,
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls-play-button {
	opacity: 0;
	pointer-events: none;
	display: none;
}
.remoteVideo {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	object-fit: cover;
}

.input-container>label,
.input-container>input:not([value=""])~label,
.textarea-container>label,
.textarea-container>textarea:empty~label,
.dropdown-container>label {
	position: absolute;
	left: 12px;
	top: 10px;
	transition: .2s;
	font-size: 14px;
}

.input-container>input:focus~label,
.input-container>input:not(:placeholder-shown)~label,
.textarea-container>textarea:focus~label,
.textarea-container>textarea:not(:placeholder-shown)~label,
.dropdown-container>input:not(:placeholder-shown)~label {
	left: 12px;
	top: -16px;
	font-size: 12px;
}

input:-webkit-autofill+label {
	left: 12px;
	top: -16px;
	font-size: 12px;
}

.capitalize-first::first-letter {
	text-transform: uppercase;
}

.message-container>p {
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.truncate-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}